import querystring from 'query-string';
import { ApiRequestConfig } from '@core/src/types/api-requests/ApiRequestConfig';
import { ApiRequestConfigInfo } from '@core/src/types/api-requests/ApiRequestConfigInfo';
import { NameValueMap } from '@core/src/types/KeyValueMap';
import authenticationService from './authentication.service';

const useApiRequestService = () => {
  const handleResponse = (response: any) => response.json().then((data: any) => {
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.clearTokenLogin();
        window.location.reload();
      }
      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });

  const executeApiRequest = async (apiCallInfo: ApiRequestConfigInfo, requestConfig: ApiRequestConfig, body?: NameValueMap<any>) => {
    const { handleLoading, handleErrorNotification } = requestConfig.options;
    const fetchOptions: RequestInit = {
      method: apiCallInfo.method,
    };
    const fetchHeaders: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Functions-Key': process.env.REACT_APP_API_CODE || '',
    };

    if (apiCallInfo.sendToken) {
      fetchHeaders.Authorization = `Bearer ${requestConfig.accessToken ? requestConfig.accessToken : authenticationService.getAccessToken()}`;
    }

    if (apiCallInfo.sendFiles) {
      if (body && requestConfig.fileFields.length > 0) {
        // fileFields available
        const formDataBody = new FormData();
        Object.keys(body).forEach((field) => {
          if (body) { // why this typescript???
            if (requestConfig.fileFields.findIndex((f) => f === field) !== -1) {
              // fileField
              formDataBody.append(field, body[field]);
            } else {
              // dataField
              formDataBody.append(field, JSON.stringify(body[field]));
            }
          }
        });
        fetchOptions.body = formDataBody;
        delete fetchHeaders['Content-Type'];
      } else {
        fetchOptions.body = JSON.stringify(body); // no files to transfer => JSON-Body
      }
    } else if (body && (apiCallInfo.method === 'POST' || apiCallInfo.method === 'PUT')) {
      fetchOptions.body = JSON.stringify(body);
    }

    fetchOptions.headers = fetchHeaders;

    if (handleLoading) {
      /* thisNotificationId = this.notificationsService.showNotification(
          {type: 'loading', message: 'NotificationPleaseWait', delayShowMs: 1000}
      ); */
    }
    let urlApiEndpoint = apiCallInfo.endpoint;
    if ('replaceUrl' in apiCallInfo && apiCallInfo.replaceUrl) {
      Object.keys(apiCallInfo.replaceUrl).forEach((replaceKey) => {
        if (apiCallInfo.replaceUrl) {
          urlApiEndpoint = urlApiEndpoint.replace((`{${replaceKey}}`), (`${apiCallInfo.replaceUrl[replaceKey]}`));
        }
      });
    }
    urlApiEndpoint = process.env.REACT_APP_API_BASEURL + urlApiEndpoint;

    if (body && apiCallInfo.method === 'GET') {
      // url params
      const queryString = querystring.stringify(body);
      urlApiEndpoint = `${urlApiEndpoint}?${queryString}`;
    }
    return fetch(urlApiEndpoint, fetchOptions)
      .then(handleResponse).then(
        (result) => result,
        (err) => {
          if (handleErrorNotification) {
            // self.notificationsService.showNotification( {type: 'error', message: errorCodeString});
          }
          console.log('executeApiRequest Error:', err);
          return Promise.reject(err);
        },
      );
  };

  const callApi = async (apiConfig: ApiRequestConfig) => {
    if (!apiConfig.info) return Promise.resolve('Api Config invalid');
    const { info, body, ...config } = apiConfig;
    return executeApiRequest(info, config, body);
  };

  return { callApi };
};

export default useApiRequestService;
