import { UserAuthType, UserType } from '@core/src/types/UserType';

const getTokenData = (): UserAuthType | null => {
  const storageToken = localStorage.getItem('userToken');
  if (storageToken) {
    const userTokenData = JSON.parse(storageToken);
    return userTokenData || null;
  }
  return null;
};

const hasTokenLogin = () => !!getTokenData();

const getUserFromToken = (): UserType | null => getTokenData();

const clearTokenLogin = () => {
  localStorage.removeItem('userToken');
};

const getAccessToken = () => {
  const tokenData = getTokenData();
  return (tokenData ? tokenData.accessToken : '');
};

const setLoginToken = (tokenData: UserAuthType) => {
  localStorage.setItem('userToken', JSON.stringify(tokenData));
};

const authenticationService = {
  getTokenData,
  hasTokenLogin,
  getUserFromToken,
  clearTokenLogin,
  getAccessToken,
  setLoginToken,
};
export default authenticationService;
